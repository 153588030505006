<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="
              visible = true;
              dialogAdd = true;
            "
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.site_id"
              filterable
              clearable
              placeholder="加油站（可输入）"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="searchParams.manufacturer"
              clearable
              placeholder="生成厂家"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-arrow-up"
              size="mini"
              @click="handleExport"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #name="{ scope }">
        {{ scope.row.name }}
        <el-tooltip
          v-if="scope.row.remark"
          :content="`备注: ${scope.row.remark}`"
        >
          <el-link
            :type="
              scope.row.con_status === 2
                ? 'danger'
                : scope.row.con_status === 1
                ? 'warning'
                : 'default'
            "
            :underline="false"
          >
            <i class="el-icon-chat-line-square" style="font-size: 18px"></i>
          </el-link>
        </el-tooltip>
        <!-- <el-tag v-if="scope.row.con_status === 0" type="success" size="small">
          在线
        </el-tag>
        <el-tag v-else type="danger" size="small">离线</el-tag> -->
      </template>
      <template #state="{ scope }">
        <el-link :type="scope.row.state == 0 ? 'success ' : 'danger'">{{
          statusMap[scope.row.state]
        }}</el-link>
      </template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="mini"
          @click="handleGetRow(scope.row.id)"
        >
          编辑
        </el-button>
        <el-button type="danger" size="mini" @click="handleDel(scope.row.id)"
          >删除</el-button
        >
      </template>
    </default-table>

    <!-- 加油机详情 -->
    <el-dialog
      :title="dialogAdd ? '新增' : '修改'"
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <!-- @close="handleCancel" -->
      <ele-form
        v-model="RowInfo"
        :form-desc="formDesc"
        labelPosition="right"
        :span="24"
        ref="form"
        :rules="rules"
        :isShowCancelBtn="true"
        :isShowBackBtn="false"
        :request-fn="handleSubmit"
        :isShowErrorNotify="false"
        @cancel="handleCancel"
      >
        <template
          v-slot:form-footer
          v-if="RowInfo.fuel_gun * 1 > 0 && RowInfo.fuel_gun * 1 <= 20"
        >
          <el-col
            :span="24"
            v-for="(item, index) in RowInfo.fuel_gun * 1"
            :key="index"
          >
            <gun-item :item="item" :form="RowInfo" :index="index"></gun-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                v-model="RowInfo.remark"
                placeholder="请输入备注（可选）"
                maxlength="60"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </template>
      </ele-form>
      <div style="text-align: center" v-if="false">
        <el-button type="info" plain size="mini" @click="visible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import GunItem from './components/GunItem';
import EleForm from 'vue-ele-form';
import {
  QueryMachine,
  ExportMachine,
  DelMachine,
  MachineAddForm,
  MachineEditForm,
  MachineRow
} from '@/api/gas_info_mgr';

import { columns, formDesc } from './columns/Machine';
import { getRegisterFactory, getGasData } from '@/api/global';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable,
    EleForm,
    GunItem
  },
  data() {
    // const self = this;
    return {
      tableLoading: true,
      dialogAdd: true,
      searchParams: {
        site_id: '',
        manufacturer: ''
      },
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      visible: false,
      RowInfo: {
        fuel_gun: 1
      },
      rules: {
        site_id: [{ required: true, message: '请输入加油站名称' }],
        name: [{ required: true, message: '请输入加油机名称' }],
        tanker_code: [{ required: true, message: '请输入加油机编号' }],
        manufacturer: [{ required: true, message: '请输入生产厂家' }],
        machine_type: [{ required: true, message: '请输入型号' }],
        factory_num: [{ required: true, message: '请输入出厂编号' }],
        start_date: [{ required: true, message: '请输入出厂日期' }],
        num: [{ required: true, message: '请输入设备编号' }],
        fuel_gun: [
          {
            required: true,
            trigger: 'change',
            type: 'number',
            max: 20,
            validator: (rule, value, callback) => {
              if (value > 0) {
                if (value > 20) {
                  return callback('油枪数量不大于20个！');
                } else {
                  return callback();
                }
              } else {
                return callback('油枪数量必须为大于0的数字值');
              }
            }
          }
        ]
      },
      // GunInfo: {},
      statusMap: ['启用', '停用'],
      formDesc: {
        site_id: {
          type: 'select',
          layout: 12,
          label: '加油站名称',
          prop: {
            text: 'name',
            value: 'id'
          },
          options: async () => {
            const result = await getGasData();
            if (result && result.code === 0) {
              return result.data;
            } else {
              return [];
            }
          },
          attrs: {
            filterable: true
          }
        },
        name: {
          type: 'input',
          label: '加油机名称',
          layout: 12
        },
        tanker_code: {
          type: 'input',
          label: '加油机编号',
          layout: 12
        },
        manufacturer: {
          type: 'select',
          layout: 12,
          label: '生产厂家',
          prop: {
            text: 'name',
            value: 'name'
          },
          options: async () => {
            const result = await getRegisterFactory({ type: 2 });
            if (result && result.code === 0) {
              return result.data;
            } else {
              return [];
            }
          },
          attrs: {
            filterable: true
          }
        },
        ...formDesc
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryMachine(params).then(res => {
        // console.log(res);
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportMachine(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    async handleGetRow(id) {
      let res = await MachineRow({ id });
      let { code, data } = res;
      if (code === 0) {
        this.visible = !this.visible;
        this.dialogAdd = false;
        this.RowInfo = data;
        this.RowInfo.num = data.con_num;
        this.RowInfo.state = this.RowInfo.state === 0 ? true : false; // 加油机状态 0:启用 1:停用
      }
    },
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      console.log(params);

      const self = this;
      let flag = true;
      for (var i = 0; i < params.fuel_gun; i++) {
        if (
          self.RowInfo[`type${i + 1}`] != '关闭' &&
          self.RowInfo[`type${i + 1}`] != undefined &&
          (self.RowInfo[`port${i + 1}`] === undefined ||
            self.RowInfo[`port${i + 1}`] === '')
        ) {
          flag = false;
          break;
        }
      }
      if (flag) {
        params.state = params.state ? 0 : 1; // 加油机状态 0:启用 1:停用
        let result;
        if (this.dialogAdd) {
          result = await MachineAddForm(params);
        } else {
          result = await MachineEditForm(params);
        }
        if (result && result.code === 0) {
          this.$message.success(result.msg);
          this.handleCancel();
          return result;
        } else {
          this.$message.error(result.msg);
        }
      }
    },
    handleDel(id) {
      this.$confirm('是否删除该加油机？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          DelMachine({ id }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.handleCancel();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleCancel() {
      this.visible = false;
      this.dialogAdd = false;
      this.RowInfo = {
        fuel_gun: 1
      };
      // this.GunInfo = {};
      this.$refs['form'].resetForm();
      this.handleQuery({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
