<template>
  <div>
    <el-col :span="12">
      <el-form-item :label="`${item}号加油枪`">
        <el-select
          v-model="form['type' + (index + 1)]"
          filterable
          placeholder="请选择油品"
          style="width: 100%;"
        >
          <!-- @change="$forceUpdate()" -->
          <el-option value="关闭" label="关闭" />
          <el-option
            v-for="items in oilTypes"
            :key="items"
            :value="items"
            :label="items"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="所属端口" style="padding-left: 16px;">
        <el-radio-group v-model="form['port' + (index + 1)]">
          <el-radio :label="1">端口一</el-radio>
          <el-radio :label="2">端口二</el-radio>
          <el-radio :label="3">端口三</el-radio>
          <el-radio :label="4">端口四</el-radio>
        </el-radio-group>
        <span
          class="msg"
          v-if="
            (form[`port${index + 1}`] === undefined ||
              form[`port${index + 1}`] === '') &&
              form[`type${index + 1}`] != undefined &&
              form[`type${index + 1}`] != '关闭'
          "
          >请选择</span
        >
      </el-form-item>
    </el-col>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Number
    },
    index: {
      type: Number
    },
    form: {
      type: Object
    },
    rule: {
      type: Object
    }
  },
  data() {
    return {
      oilTypes: [
        '92#汽油',
        '95#汽油',
        '93#汽油',
        '97#汽油',
        '98#汽油',
        '101#汽油',
        '0#柴油',
        '5#柴油',
        '-10#柴油',
        '-20#柴油',
        '-35#柴油',
        '-50#柴油'
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.msg {
  color: #f56c6c;
  position: relative;
  top: 8px;
  line-height: 0;
  display: inherit;
}
</style>
