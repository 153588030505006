export const columns = [
  {
    title: '序号',
    key: 'index',
    align: 'center',
    fixed: true,
    scopedSlots: { customRender: 'index' },
    width: 64
  },
  {
    title: '加油站名称',
    key: 'site_name',
    fixed: true,
    minWidth: 210
  },
  {
    title: '加油机名称',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    minWidth: 160
  },
  {
    title: '加油机设备编号',
    key: 'con_num',
    minWidth: 140
  },
  {
    title: '加油机型号',
    key: 'machine_type',
    minWidth: 100
  },
  {
    title: '生产厂家',
    key: 'manufacturer',
    minWidth: 160
  },
  {
    title: '出厂日期',
    key: 'start_date',
    width: 120
  },
  {
    title: '端口数量',
    key: 'tax_port_num',
    align: 'center',
    width: 100
  },
  {
    title: '油枪数',
    key: 'fuel_gun',
    align: 'center',
    width: 100
  },
  {
    title: '状态',
    // fixed: 'right',
    key: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 160,
    scopedSlots: { customRender: 'actions' }
  }
];

export const formDesc = {
  machine_type: {
    type: 'input',
    label: '型号',
    layout: 12
  },
  factory_num: {
    type: 'input',
    label: '出厂编号',
    layout: 12
  },
  start_date: {
    type: 'date',
    label: '出厂日期',
    layout: 12,
    attrs: {
      'value-format': 'yyyy-MM-dd'
    }
  },
  num: {
    type: 'input',
    label: '设备编号',
    layout: 12
  },
  fuel_gun: {
    type: 'input',
    label: '加油枪数量',
    layout: 12
  },
  state: {
    type: 'switch',
    label: '加油机状态',
    layout: 12,
    attrs: {
      activeText: '启用',
      inactiveText: '停用'
    }
  }
};
